body{
	background:black;

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.MyHeader-typewriter{
  color:black;
  font-size: 40px;
  padding-top: 0px;
  position: absolute;
  z-index: 22;
  width: 100%;
  left: 0px;
  text-align: center;

}

.MyHeader{
  background-color: turquoise;
  text-align: center;
  padding: 20px;

}

.MyHeader-Name{

}

.MyHeader-Profession{
  padding-top: -10px;
}
.not-bold{
  font-weight: normal;
}

.MyHeader-Outer-Image{
  padding-top:45px;
}

.MyHeader-Image{
  vertical-align: middle;
  border-color: black;
  border-style: solid;
  
  width: 200px;
  height: 200px;
  border-radius: 50%;

}

.AboutMe{
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  color:white;
  
  


}

.AboutMe-Title{
  text-align: center;
  flex: 50%;
  color: white;

}

.AboutMe-Content{
  flex:90%;
  width:35% 
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
